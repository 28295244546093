.createdDate {
    position: absolute;
    right: 2rem;
}

.comments_section {
    border-top: 1px solid #ddd;
}

.comments_section p {
    margin-bottom: 0;
}

.user_email,
p.createdDate {
    color: var(--lightTextColor);
}