.buttons_container {
    width: 30rem;
}

.radio_set {
    font-size: 18px;
}

.media_btn {
    border-radius: 15px;
    background-color: var(--defaultColor);
    color: var(--defaultTextColor);
    font-weight: bold;
    display: block;
    width: inherit;
    padding: 12px;
    margin: 10px 0;
    border: none;
    font-size: 22px;
    text-align: center;
}

.image_container {
    width: 30rem;
    border-radius: 2rem;
    margin-bottom: 3rem;
    overflow: hidden;
}

.image_container img {
    width: inherit;
}