.nav_menu_list {
    list-style: none;
    padding-left: 1px;
}

.nav_menu_list li {
    padding: 10px 8px;
}

.nav_menu_list li a {
    text-decoration: none;
    color: #333;
    font-size: 22px;

}

.navLink.active {
    color: var(--defaultColor);
    font-weight: bold;
}

.nav_menu_list li a p {
    margin: 0
}

.setting_list {
    position: absolute;
    bottom: 20px;
}

.side_nav_section {
    width: 18%;
    display: inline-block;
    background-color: var(--primaryColor);
    height: 93vh;
    position: fixed;
    transition: width ease-in 0.5s;
}

.side_nav_section .nav_menu_list p {
    transition-delay: 0.3s;
    transition: font-size ease 0.2s;
}

.shrink p {
    display: block;
    font-size: 10px;
    text-align: center;
}

.shrink p svg {
    margin-right: 0;
}


.side_nav_section.shrink {
    width: 5%;
}

.page_contents {
    margin-left: 18%;
    width: 80%;
    padding: 20px;
    text-align: left;
    display: inline-block;
    height: 93vh;
    overflow: scroll;
    transition: all ease .5s;
    transition-delay: 0.5s;
}

.page_contents.expand {
    margin-left: 5%;
}

.nav_menu_list svg {
    width: 25px;
    height: 25px;
    color: #777;
    margin-right: 12px;
}