.top_nav {
    width: 100%;
    padding: 5px 10px;
    background-color: var(--defaultColor);
    color: #fff;
    height: 7vh;
}

.brand,
.brandback {
    margin-left: 1em;
    padding: 12px;
    display: inline-block;
    font-size: 22px;
}

.brand h4 {
    margin: 0;
}

.user_actions {
    float: right;
    margin-right: 50px;
    padding: 20px;
}

.brandback a,
.brandback h4 {
    display: inline;
}

.user_actions a {
    padding: 5px;
}

.logout_btn {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    font-size: 14px;
    color: var(--defaultColor);
}

.version_number{
    right: 7.5rem;
    position: absolute;
}

.apps_icon {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    font-size: 22px;
    color: var(--defaultTextColor);
}