:root {
  --defaultColor: #05564e;
  --primaryColor: #eee;
  --defaultTextColor: #eee;
  --secondaryTextColor: #36c3b4;
  --logincardbackground: #05564e80;
  --lightTextColor: #888;
  --lightBorderColor: #bbb;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main_page {
  display: inline;
}

.login_main {
  height: 100vh;
  background-image: url('./assets/landing.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-size: cover;
}

.login_main .login {
  height: inherit;
}

.page_title h1,
.page_title p {
  margin: 4px;
}

.page_title p {
  color: #666;
}

.inp_radio {
  width: 18px;
  height: 18px;
  margin: 15px 0 0 20px;
}

.spinner_container {
  height: 80vh;
}

.spinner_container svg {
  margin: auto;
}

.line-seperator {
  border-bottom: 1px solid #ccc;
  margin: 1rem 0;
}

/*----------------------------
Login Page Styles
--------------------------*/
.login {
  align-content: center;
}

.login_card {
  background-color: var(--logincardbackground);
  border-radius: 1.5rem;
  padding: 1rem;
  color: var(--defaultColor);
  margin: auto auto;
  box-shadow: 2px 2px 10px #444;
}

.inputsGroup .form-control {
  border-radius: 0.8rem;
  box-shadow: 2px 2px 10px #444;

}

.inputsGroup {
  padding: 1rem 5rem;
}

.app_card {
  display: inline;
}

.app_card {
  display: inline-block;
  text-align: center;
}

.app_card img {
  width: 8rem;
  border: 1px solid #ccc;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}