.user_image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    overflow: hidden;
}

.user_image2 {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    overflow: hidden;
}

.user_image img,
.user_image2 img {
    width: inherit;
}

.user_card {
    background-color: #fff;
    box-shadow: 1px 1px 5px #ccc;
    padding: 1rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
}

.row_container {
    display: inline;
}

.subscription p {
    margin-bottom: 0;
}

.user_details h4 {
    margin-bottom: 1px;
}