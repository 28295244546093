.subject_card {
    width: 14rem;
    height: 20rem;
    border-radius: 2rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: inline-block;
    overflow: hidden;
    background-size: contain;
    position: relative;
    background-color: var(--defaultColor);
}

.flash_card {
    width: 11rem;
    height: 18rem;
    border-radius: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: inline-block;
    overflow: hidden;
    background-size: contain;
    position: relative;
    background-color: var(--defaultColor);
    border: 1px solid var(--lightBorderColor);
}

.flash_card canvas {
    width: 174px !important;
    height: 288px !important;
}

.subject_title {
    background: #fffe;
    padding: 1rem 0.5rem 0.5rem 1rem;
}

.subject_title h4,
.subject_title p {
    margin-bottom: 2px;
    color: var(--defaultColor) !important;
}

.subject_card .options {
    position: absolute;
    display: block;
    background-color: #fffe;
    bottom: 0;
    width: 14rem;
    color: var(--defaultColor) !important;
}

.subject_card .counts {
    position: absolute;
    display: block;
    background-color: #fffe;
    border-bottom: 2px solid var(--defaultColor);
    bottom: 3.6rem;
    padding: 0 4px;
    width: 14rem;
}

.subject_card .options p {
    display: inline-block;
    padding: 1rem 1.7rem;
    margin-bottom: 2px;
}

.subject_card .counts p {
    display: inline-block;
    padding: 8px 20px;
    margin-bottom: 2px;
    border-right: 1px solid #aaa;
}

.subject_card .counts p img {
    width: 30px;
}

.flash_card .options {
    position: absolute;
    display: block;
    background-color: #fffe;
    bottom: 0;
    width: 14rem;
    color: var(--defaultColor) !important;
}

.btn-back-1 {
    background-color: var(--defaultColor);
    color: var(--defaultTextColor);
    position: absolute;
    right: 3.5rem;
    margin-top: -3rem;
    transition: all ease 0.2s;
}

.btn-back-1:hover {
    background-color: var(--defaultTextColor);
    color: var(--defaultColor);
}

.add_pointer {
    cursor: pointer;
}

.color_white {
    color: white;
}

.no_cursor {
    cursor: not-allowed;
}

.flash_card .options p,
.flash_card .options a {
    display: inline-block;
    padding: 1rem 1rem;
    margin-bottom: 2px;
    cursor: pointer;
    text-decoration: none;
    color: var(--defaultColor);
}


.counts p:last-child {
    border-right: none;
}

.options p:first-child {
    border-right: 1px solid #aaa;
}

.options p svg {
    margin-right: 4px;
}

p.subject_counts {
    font-size: 14px;
}

.overlay_dark {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #000a;
    z-index: 5;
}

.left-modal {
    width: 40vw;
    background-color: var(--defaultTextColor);
    margin: auto;
    padding-bottom: 2rem;
    border-radius: 1rem;
    transform: translate(0, 20%);
}

.hide {
    display: none !important;
}

.modal_header {
    background-color: var(--defaultColor);
    color: var(--defaultTextColor);
    padding: 1rem;
}

.modal_header h4 {
    margin-bottom: 0;

}

.modal_close {
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
}

.center-align {
    text-align: center;
}

.subjectposter {
    height: 16rem;
    width: 11rem;
    border: 1px dashed #aaa;
    text-align: center;
    vertical-align: middle;
    border-radius: 1rem;
    padding: 0;
    margin: auto;
    align-content: center;
    overflow: hidden;
    cursor: pointer;
}

.subjectposter img {
    width: inherit;
    height: inherit;
}

.subPosterlabel {
    position: relative;
}

.submit_btn {
    background-color: var(--defaultColor);
    color: var(--defaultTextColor);
}

.left-modal .radio_set {
    font-size: 15px;
}

.subjectposter canvas {
    width: 165px !important;
    height: 260px !important;
}