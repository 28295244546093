.stats {
    display: block;
    width: 80%;
    padding-top: 1.33em;
}

.stat_card {
    background-color: var(--defaultColor);
    color: #eee;
    border-radius: 1.3em;
    padding: 1em;
    width: 30%;
    display: inline-block;
    margin-left: 1em;
    overflow: hidden;
}

.stat_card h2,
.stat_card p,
.tables h2,
.tables p {
    margin: 4px;
}

.stat_card b {
    width: 100%;
    float: right;
    font-size: 35px;
    padding-top: 0.5em;
    text-wrap: wrap;
}

.stat_card b span {
    color: var(--secondaryTextColor);
}

.tables .tbl_users,
.tables .tbl_forums {
    width: 45%;
    display: inline-block;
}

.view_all_action {
    float: right;
    font-size: 22px;
    margin-top: 22px;
}